$mcc-teal: #0092ac;
$ka-blue: #2255a0;
$horizon-red: #E41F2C;
$precisepilot-blue: rgb(0, 118, 188);

body, html {
    margin: 0;
    overflow: hidden;
    -webkit-transition: opacity 400ms;
    -moz-transition: opacity 400ms;
    transition: opacity 400ms;
}

body, .onepage-wrapper, html {
    display: block;
    position: static;
    padding: 0;
    width: 100%;
    height: 100%;
}

body {
    background-color: #F8E71C;
    transition: background-color 1s;
    font-family: "adelle-sans", sans-serif;
    user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "justus-pro", serif;
}

.onepage-wrapper {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    padding: 0;
    //-webkit-transform-style: preserve-3d;
}

.onepage-wrapper .section {
    width: 100%;
    height: 100%;
    position: relative;
}

.onepage-pagination {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    list-style: none;
    margin: 0;
    padding: 0;
}
.onepage-pagination li {
    padding: 0;
    text-align: center;
}
.onepage-pagination li a{
    padding: 10px;
    width: 4px;
    height: 4px;
    display: block;
    
}
.onepage-pagination li a:before{
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    background: rgb(27, 23, 72);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
}

.onepage-pagination li a.active:before{
    width: 10px;
    height: 10px;
    background: none;
    border: 1px solid black;
    margin-top: -4px;
    left: 8px;
}

.disabled-onepage-scroll, .disabled-onepage-scroll .wrapper {
    overflow: auto;
}

.disabled-onepage-scroll .onepage-wrapper .ops-section {
    position: relative !important;
    top: auto !important;
}
.disabled-onepage-scroll .onepage-wrapper {
    -webkit-transform: none !important;
    -moz-transform: none !important;
    transform: none !important;
    -ms-transform: none !important;
    min-height: 100%;
}

.disabled-onepage-scroll .onepage-pagination {
    display: none;
}

body.disabled-onepage-scroll, .disabled-onepage-scroll .onepage-wrapper, html {
    position: inherit;
}

.wrapper {
    position: relative;
    width: calc(100% - 100px);
    height: calc(100% - 100px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

section {
    background-position: center;
    background-size: cover;
}

section.mcc-checkout {
    background-image: url(../img/bkg/MCC-w1440.jpg);
    
    div.page_information::-webkit-scrollbar {
        background-color: $mcc-teal;
    }
}

section.ka-erp {
    background-image: url(../img/bkg/KA-w1440.jpg);
    
    div.page_information::-webkit-scrollbar {
        background-color: $ka-blue;
    }
}

section.horizon-game {
    background-image: url(../img/bkg/Horizon-w1440.jpg);
    
    div.page_information::-webkit-scrollbar {
        background-color: $horizon-red;
    }
}

section.precise-pilot {
    background-image: url(../img/bkg/Precise-w1440.jpg);
    
    div.page_information::-webkit-scrollbar {
        background-color: $precisepilot-blue;
    }

    div.page_detail img {
        max-height: 100%;
        object-fit: contain;
        margin: 0 auto;
    }
}

section.about {
    div.page_container {
        flex-direction: row-reverse;
        right: 42px;
    }
    
    div.page_information {
        color: #2d2a28;
        max-width: 32%;
        
        img {
            width: 100%;
            filter: grayscale(.7);
        }
    }
}

div.main section {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

section.homepage {
    div.mark_container {
        user-select: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 600;
        color: white;
        text-align: center;
        text-shadow: 0px 0px 3px rgba(43,35,70,0.43);
        width: 90%;
        
        div.side_mark {
            border-top: 2px solid white;
            display: inline-block;
            width: 8rem;
            vertical-align: text-top;
            position: relative;
            margin-top: -2px;
            margin-right: 10px;
            margin-left: 10px;
            box-shadow: 0px 0px 3px rgba(43,35,70,0.43);
            top: 4px;
            white-space: nowrap;
        }
        
        h1,
        h2 {
            font-family: "fairview-smallcaps", sans-serif;
        }
        
        h1 {
            font-size: 9rem;
            line-height: 7rem;
            border: 7px solid white;
            padding: 7px 44px;
            margin: 0;
            margin-bottom: 10px;
            box-shadow: 0px 0px 3px rgba(43,35,70,0.43);
            display: inline-block;
        }
        
        h2 {
            font-size: 3rem;
            margin: 0;
            padding: 0;
            display: inline;
        }
    }
}

div.video-bkg-container {
    position: fixed;
    width: 100%;
    height: 100%;
}

video.video-bkg,
video.video-bkg img {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    max-width: 1000%;
    max-height: 1000%;
    text-align: center;
    width: auto;
    height: auto;
    overflow: hidden;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}

div.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F8E71C;
    mix-blend-mode: multiply;
    -webkit-transition: background-color 1s;
    transition: background-color 1s;
}

div.logo {
    z-index: 1;
    position: absolute;
    top: 50px;
    left: 107px;
    width: 86px;
    height: 86px;
    background-color: #F8E71C;
    text-align: center;
    transition: background-color 1s;
    
    svg {
        position: relative;
        top: 10px;
        filter: drop-shadow(0 0 2px rgba(43,35,70,.07));
    }
}

div.page_information {
    transition: background-color 1s;
    background-color: #F8E71C;
    padding: 20px;
    color: white;
    max-width: 40%;
    overflow: auto;
    
    &::-webkit-scrollbar {
        background-color: #F8E71C;
        width: 6px;
    }
    
    &::-webkit-scrollbar-thumb {
        background: rgba(43,35,70,0.43);
    }
    
    h1 {
        font-size: 2.3rem;
        line-height: 2.2rem;
        margin: 0;
    }
    
    h2 {
        font-size: 1.5rem;
        margin: 0;
    }
}

div.page_detail {
    padding: 0 0 0 50px;
    text-align: center;
    
    video {
        width: 100%;
        height: auto;
    }
}

div.page_container {
    position: absolute;
    top: 25%;
    right: 57px;
    bottom: 0;
    left: 57px;
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    
    div {
        flex-grow: 1;
    }
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    
    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes fadeOutDown {
    from {
        opacity: 1;
    }
    
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes fadeOutDown {
    from {
        opacity: 1;
    }
    
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
}

ul.tech {
    list-style: none;
    padding-inline-start: calc(1rem + 10px);
    
    li {
        position: relative;
    }
    
    img {
        width: auto;
        height: 1rem;
        position: absolute;
        left: calc(-1rem - 10px);
        top: 4px;
    }
}

@media (max-width: 980px) {
    .onepage-pagination {
        right: 2px;
    }
    
    .wrapper {
        width: calc(100% - 50px);
        height: calc(100% - 50px);
    }
    
    div.logo {
        top: 25px;
        left: 68px;
    }
    
    div.page_detail {
        padding: 0;
        max-width: 100%;
        max-height: 50%;
        min-height: 400px;
        order: 0;
        display: inline-flex;
        margin: 20px auto;
    }
    
    div.page_information {
        max-width: 100%;
        overflow: unset;
        order: 1;
    }
    
    div.page_container {
        top: 12.5%;
        right: 43px;
        left: 43px;
        overflow: scroll;
        flex-direction: column;
        align-items: flex-start;
    }
    
    section.about {
        //background-image: url(/img/PortlandBackground.jpg);
        div.page_container {
            flex-direction: column;
            align-items: flex-end;
        }
    }  
}
